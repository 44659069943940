import React, { Component } from 'react'
import styled from 'styled-components'
import splash from './splash.jpg';

const TextSectionWrapper = styled.div`
  justify-content: center;
`

const AboutMe = styled.p`
  font-family: 'Inconsolata', monospace;
  font-size: 14px;  
  font-style: normal;
  font-weight: normal;
  line-height: normal; 
`

const ImagePreview = styled.div`
  background-image: url(${splash});
  background-size: cover;
  width: 600px;
  height: 400px;
  @media (max-width: 768px) {
    width: 400px;
    height: 266px;
    margin-top: -50px;
  }
  margin-bottom: 20px;
`;

export class Teaser extends Component {
  render () {
    return (
      <TextSectionWrapper>
        <ImagePreview />
        <AboutMe>
          Coming soon...
        </AboutMe>
      </TextSectionWrapper>
    )
  }
}
