import './App.css';
import { Teaser } from './Teaser.jsx';

function App() {
  return (
    <div className="App">
      <div className="App-contents">
        <Teaser/>
      </div>
    </div>
  );
}

export default App;
